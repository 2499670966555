import { AppProps } from 'next/app';

import 'styles/globals.css';
import 'styles/aoform.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import {Layout} from "../components/layout";
import {BlurMapContext} from "../context/blur-map-context";
import Script from "next/script";
import {useEffect, useState} from "react";
import {DictionaryContext} from "../context/dictionary-context";

export default function App({ Component, pageProps }: AppProps) {
  const [loadScripts, setLoadScripts] = useState<boolean>(false);
  const title = pageProps?.entity?.title || pageProps?.entity?.name || pageProps?.title;
  const menus = pageProps?.menus;

  useEffect(() => {
    const beginScripts = () => !loadScripts && setLoadScripts(true);
    window.addEventListener('mousemove', beginScripts);
    window.addEventListener('touchmove', beginScripts);
    return () => {
      window.removeEventListener('mousemove', beginScripts);
      window.removeEventListener('touchmove', beginScripts);
    }
  }, []);

  // Unregister service worker.
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  }, []);

  return (
    <>
      <DictionaryContext.Provider value={pageProps?.dictionary}>
        <Layout title={title}
                menus={menus}
                loadScripts={loadScripts}
                webforms={pageProps?.webforms}
                metatag={pageProps?.metatag}
                entityInfo={pageProps?.entityInfo}
                newsletterPad={pageProps?.newsletterPad}
                customMenu={pageProps?.customMenu}
                alertBanner={pageProps?.alertBanner}
                contactFormTitle={pageProps?.contactFormTitle}
                contactFormDescription={pageProps?.contactFormDescription}
                logoScrollToTop={pageProps?.logoScrollToTop}
                hideUtilityMenu={pageProps?.hideUtilityMenu}
                hideFooterMenu={pageProps?.hideFooterMenu}
                redirectTo={pageProps?.redirectTo}
                dictionary={pageProps?.dictionary}
                translatedUrls={pageProps?.translatedUrls}
        >
          <BlurMapContext.Provider value={pageProps?.blurMap}>
            <Component {...pageProps} />
          </BlurMapContext.Provider>
        </Layout>
      </DictionaryContext.Provider>
      {process?.env?.NEXT_PUBLIC_GTM && (
        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM}');
          `,
          }}
        />
      )}
      {loadScripts && (
        <>
        {/* @todo removed velaro, retaining in case it needs to be added back - 10/10/2024 */}
        {/*  <Script*/}
        {/*    id="velaro-loader"*/}
        {/*    type="text/javascript" strategy="afterInteractive" dangerouslySetInnerHTML={{*/}
        {/*    __html: `*/}
        {/*  (function(){var w=window;var d=document;if(w.Velaro){return;}var v=function(){return v.c(arguments)};v.q=[];v.c=function(args){v.q.push(args)};w.Velaro=v;v.endpoints={mainApi:'https://api-main-us-east.velaro.com/',cdn:'https://eastprodcdn.azureedge.net/'};w.addEventListener('load',function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=v.endpoints.cdn+'widgets/shim';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);});Velaro('boot',{siteId:20065,groupId:0,customVars:{exampleKey1:'exampleValue1',exampleKey2:'exampleValue2'}});}());*/}
        {/*`,*/}
        {/*  }}*/}
        {/*  />*/}
        </>
      )}
    </>
  )
}
